import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import welcomeReducer from '../services/welcomeSlice';
import storestatusReducer from '../services/storestatusSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    welcome: welcomeReducer,
    storestatus: storestatusReducer,
  },
});
