import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { joinSession } from "../services/welcomeSlice";
import placeholder from '../assets/images/profile-placeholder.jpg'

const JoinSession = (props) => {
    console.log("Join Session", props)
    
    const dispatch = useDispatch()
    

    const joinSessionHandler = () => {
        console.log("Join Session Handler", props.data[0].session_link)
        dispatch(joinSession({session_id: props.data[0].id}))
        .then(() => {
            window.location.href = props.data[0].session_link
        })        
    }

    let All_mediaDevices=navigator.mediaDevices
    if (!All_mediaDevices || !All_mediaDevices.getUserMedia) {
        console.log("getUserMedia() not supported.");
        return;
    }
    All_mediaDevices.getUserMedia({
        audio: false,
        video: true
    })
    .then(function(vidStream) {
        var video = document.getElementById('videoCam');
        if ("srcObject" in video) {
            video.srcObject = vidStream;
        } else {
            video.src = window.URL.createObjectURL(vidStream);
        }
        video.onloadedmetadata = function(e) {
            video.play();
        };
    })
    .catch(function(e) {
        console.log(e.name + ": " + e.message);
    });

    return(
        <>
            <div className='ly-welcomebox'>
                <div className="ly-imgbox">
                    {/* <img src={`${process.env.REACT_APP_PROFILE_PIC}${props.user.profilepic}`} alt={props.user.fullname} /> */}
                    <img src={props.user.profilepic ? `${process.env.REACT_APP_PROFILE_PIC}${props.user.profilepic}` : placeholder} alt={props.user.fullname} />
                </div>
                <h2 className="ly-fs-6 ly-mb-3">{props.user.fullname}</h2>
                <p>is ready to join for a live <br /> shopping session</p>
                <div className="ly-mb-4">
                    <div className="resvideo">
                        <video id="videoCam"></video>
                    </div>
                </div>
                <div>
                    <button type="button" className="ly-btn ly-btn-primary ly-btn-lg ly-w-100" onClick={() => joinSessionHandler()}>Join session</button>
                    {/* <Link to={props.data[0].session_link} className="ly-btn ly-btn-primary ly-btn-lg ly-w-100">Join session</Link> */}
                </div>
            </div>
        </>
    )
}

export default JoinSession