import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    status: 'Idle',
    resData: '',
    cancelresData: '',
    error:'',
    joinSessionData:''
}

export const makeRequest = createAsyncThunk(
    'makeRequest/welcome',
    async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }   
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/session-request`, data, {headers: headers})
        return res.data
    }
)

export const cancelRequest = createAsyncThunk(
    'cancelRequest/welcome',
    async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }   
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/session-cancel`, data, {headers: headers})
        return res.data
    }
)

export const joinSession = createAsyncThunk(
    'joinSession/welcome',
    async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }   
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/join-session`, data, {headers: headers})
        return res.data
    }
)

export const welcomeSlice = createSlice({
    name:'welcome',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(makeRequest.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(makeRequest.fulfilled, (state, action) => {
            state.status = 'true'
            state.resData = action.payload
            state.error = ''
        })
        .addCase(makeRequest.rejected, (state, action) => {
            state.status = 'false'
            state.resData = ''
            state.error = action.error.message
        })
        .addCase(joinSession.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(joinSession.fulfilled, (state, action) => {
            state.status = 'true'
            state.joinSessionData = action.payload
            state.error = ''
        })
        .addCase(joinSession.rejected, (state, action) => {
            state.status = 'false'
            state.joinSessionData = ''
            state.error = action.error.message
        })
        .addCase(cancelRequest.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(cancelRequest.fulfilled, (state, action) => {
            state.status = 'true'
            state.cancelresData = action.payload
            state.error = ''
        })
        .addCase(cancelRequest.rejected, (state, action) => {
            state.status = 'false'
            state.cancelresData = ''
            state.error = action.error.message
        })
        
    }
})

export default welcomeSlice.reducer