import React from 'react';
import './assets/scss/style.scss';
import Welcome from './container/Welcome';



function App() {
  return (
    <Welcome />
   )
}

export default App;
