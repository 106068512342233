import React, { useEffect, useState } from 'react';
import { ReactComponent as CartIcon } from '../assets/images/cart-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/images/close-icon.svg';
import { ReactComponent as RoundCartIcon } from '../assets/images/round-cart-icon.svg';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import JoinSession from './JoinSession';
import { useDispatch, useSelector } from 'react-redux';
import { cancelRequest, makeRequest } from '../services/welcomeSlice';
import { checkStatus } from '../services/storestatusSlice';
import io from "socket.io-client";
import loader from '../assets/images/loader.gif'

const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);

const Welcome = () => {
    const welcomeValidationSchema = Yup.object({
        email_id: Yup.string().email('Invalid email address').required().label('email'),
    })

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [saveSocketData, setSaveSocketData] = useState()
    
    const [ showJoinSession, setSshowJoinSession ] = useState(false)

    const storename = window.location.hostname
    //const storename = "prakash-lively-test.myshopify.com"
    //const storename = "Awesome-store-for-unity.myshopify.com"
    
    //console.log("storename", storename)
    const { status, error, checkData } =  useSelector(state => state.storestatus)
    //console.log("checkData", checkData)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(checkStatus({store_name: storename}))
    }, [dispatch])

    const [resmsg, setResmsg] = useState(false);
    const resstatus = useSelector(state => state.welcome)
    const [isEnable, setIsEnable] = useState(false)
    const [usernotmsg, setUsernotmsg] = useState(false);
    const [saveSessionID, setsaveSessionID] = useState(false);
    
    // console.log("resmsg", resmsg)
    // console.log("resstatus", resstatus)
    // console.log("usernotmsg", usernotmsg)

    const declineReq = () => {
        dispatch(cancelRequest({store_name: storename, session_id: saveSessionID}))
        setLoading(false)
        setOpen(false)     
    }

    if(status === "Idle" || status === "loading" || checkData === '')
    {
        return <div className="text-center mt-5">...</div>
    }
    console.log(saveSessionID)
    return(
        <>
            <div className='ly-wrapper'>
            {
            open ? (
                <div className='ly-welcomebox'>
                    <div className='ly-close-icon'><button className='ly-btn' onClick={() => setOpen(false)}><CloseIcon /></button></div>
                    <div className='ly-mb-2'><RoundCartIcon /></div>
                    <h2 className='ly-mb-3'>Welcome to live shopping</h2>
                    <p>You can now shop live with our available sales team.</p>
                    <p>To get started please provide your email address in case we get disconnected</p>
                    {
                        isEnable ? (<div className="ly-error-msg "> Currently live shopping is unavailable. Please try after some time. </div>) : null
                    }
                    {
                        resstatus && resstatus.error.length ? (<div className="ly-error-msg"> {resstatus.error} </div>) : null
                    }
                    {
                        resmsg ? <div className="ly-error-msg"> {resstatus.resData.message}</div> : null
                    }
                    {
                        resmsg ? '' : (usernotmsg ? <div className="ly-error-msg"> No team member available.</div> : null)
                    }
                    <Formik 
                        initialValues={{ email_id: '' }}
                        validationSchema={welcomeValidationSchema} 
                        onSubmit={(values) => {
                            //console.log("values", values)
                            const formdata = {...values, store_name: storename}
                            //console.log("formdata", formdata)
                            setResmsg(false)
                            setUsernotmsg(false)
                            setLoading(true)
                            dispatch(checkStatus({store_name: storename}))
                            .then((res) =>{
                                if(res.payload.data.status === true) {
                                    console.log("yes")
                                    dispatch(makeRequest(formdata))
                                    .then((res) => {
                                        if(res.payload.status === 'success') {
                                            console.log("if con")
                                            // setLoading(false)
                                            // setSshowJoinSession(true)
                                            setsaveSessionID(res.payload.data.id)
                                            socket.on(`accept_request_${res.payload.data.id}`, (data) => { 
                                                console.log("accept_request socket data", data)
                                                setSaveSocketData(data)
                                                setLoading(false)
                                                setSshowJoinSession(true)
                                                localStorage.setItem('socketdata', saveSocketData)
                                            });
                                            socket.on('user_not_found', (data) => {
                                                console.log("not found socket data", data.data)
                                                setUsernotmsg(true)
                                                setLoading(false)
                                                setTimeout(() => {
                                                    setUsernotmsg(false)
                                                }, 5000)                                                
                                            });
                                        }
                                        else { 
                                            console.log("else con")
                                            setUsernotmsg(false)
                                            setResmsg(true)
                                            setLoading(false)
                                            setTimeout(() => {
                                                setUsernotmsg(false)
                                                setResmsg(false)
                                            }, 5000)

                                            
                                        }                                
                                    })
                                }
                                else {
                                    setIsEnable(true)
                                    setLoading(false)
                                    console.log("no")
                                }
                            })
                        }}>
                    {(formprops) => (
                        <Form>
                            <div className="ly-mb-3 form-control-cover">                                        
                                <input type="text" className={`ly-form-control`} id="email_id" name="email_id" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.email_id} />
                                <label htmlFor="email_id" className={`ly-form-label ${formprops.values.email_id && 'filled'}`}>Email</label>
                                <div className="error ly-fs-8 ly-mt-2 ly-text-start"> 
                                    {formprops.touched.email_id && formprops.errors.email_id ? (
                                        <div>{formprops.errors.email_id}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="">
                                <button className="ly-btn ly-btn-primary ly-btn-lg ly-w-100" type="submit" disabled={formprops.values.email_id.length === 0 ? true : false}>Start live shopping</button>
                            </div>
                        </Form>
                    )}
                    </Formik>
                </div>
            ) : null
            }
            {
                loading && (
                    <div className='ly-welcomebox'>
                        <div className='ly-close-icon'><button className='ly-btn' onClick={declineReq}><CloseIcon /></button></div>
                        <h3 className='ly-fs-6 ly-mb-3'>Connecting with a sales team member </h3>
                        <p className='ly-text-black-50 ly-mb-0 ly-position-relative'>Hang tight this may take a moment</p>
                        <div className="ly-mt-n4"><img src={`${process.env.REACT_APP_SOCKET_URL}/assets/uploads/logo/loader.gif`} alt="loading..." /></div>
                    </div>
                )
            }
            {
                showJoinSession ? <JoinSession { ...saveSocketData } /> : null
            }
            {
                checkData.status === 'success' && checkData.data.status === true ? (
                    <div className={`ly-shoplivebtn ${open ? 'ly-d-none' : null} `}>
                        <button className='ly-btn ly-btn-xlg ly-btn-primary' onClick={() => setOpen(!open)}>Shop Live <CartIcon className='ly-ms-3' /></button>
                    </div>
                ) : null
            } 
            </div>    
        </>
    )
}


export default Welcome