import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    status: 'Idle',
    checkData: '',
    error:'',
}

export const checkStatus = createAsyncThunk(
    'checkStatus/storestatus',
    async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }   
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/get-status`, data, {headers: headers})
        return res.data
    }
)

export const storestatusSlice = createSlice({
    name:'storestatus',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(checkStatus.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(checkStatus.fulfilled, (state, action) => {
            state.status = 'true'
            state.checkData = action.payload
            state.error = ''
        })
        .addCase(checkStatus.rejected, (state, action) => {
            state.status = 'false'
            state.checkData = ''
            state.error = action.error.message
        })
    }
})

export default storestatusSlice.reducer